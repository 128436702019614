<template>
  <DrawerWrapper>
    <template #prepend>
      <VContainer>
        <VRow>
          <VCol class="text-center mt-9">
            <span
              class="tt-text-title-2 tt-light-mono-100--text"
              data-test="drawer-menu-title"
            >
              {{ title }}
            </span>
          </VCol>
        </VRow>
      </VContainer>
    </template>
    <template #default>
      <VContainer>
        <VRow>
          <VCol
            v-for="(route,index) in menuItems"
            :key="index"
            cols="12"
          >
            <CardWrapper
              link
              data-test="drawer-menu-item"
              :data-test-label="`drawer-menu-item-${route.hash}`"
              min-height="auto"
              :ripple="false"
              :to="{name : route.name,hash : `#${route.hash}`}"
              class="card-link tt-text-body-2"
              active-class="card-link--active card-shadow"
            >
              {{ route.text }}
            </CardWrapper>
          </VCol>
        </VRow>
      </VContainer>
    </template>
  </DrawerWrapper>
</template>

<script>
import DrawerWrapper from '@/components/app/drawers/DrawerWrapper.vue';
import CardWrapper from '@/components/shared/CardWrapper.vue';

export default {
  name: 'NavigationDrawer',
  components: {
    CardWrapper,
    DrawerWrapper,
  },
  inject: ['Names'],
  props: {
    title: {
      type: String,
      default: 'Some title',
    },
    menuItems: {
      type: Array,
      default() {
        return [
          {
            name: this.$route.name,
            text: 'Основное',
            hash: 'main',
          },
          {
            name: this.$route.name,
            text: 'Содержание',
            hash: 'content',
          },
          {
            name: this.$route.name,
            text: 'Назначение',
            hash: 'appointment',
          },
        ];
      },
    },
  },
};
</script>

<style lang="scss">
.card-link {
  position: relative;
  overflow: hidden;

  &--active {
    &:after {
      width: 4px;
      left: 0;
      top: 0;
      height: 100%;
      content: '';
      position: absolute;
      background-color: #00A0F2 !important;
    }
  }
}
</style>
