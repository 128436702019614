import { render, staticRenderFns } from "./DrawerWrapper.vue?vue&type=template&id=5ef369b4&scoped=true&"
import script from "./DrawerWrapper.vue?vue&type=script&lang=js&"
export * from "./DrawerWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ef369b4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VNavigationDrawer})
