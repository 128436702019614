<template>
  <VNavigationDrawer
    app
    permanent
    clipped
    right
  >
    <template #prepend>
      <slot name="prepend" />
    </template>
    <slot name="default" />
  </VNavigationDrawer>
</template>

<script>
export default {
  name: 'DrawerWrapper',
};
</script>

<style scoped lang="scss">
</style>
