<template>
  <VContainer>
    <VRow>
      <VCol>
        <VTabsItems
          v-model="activeTab"
          vertical
          class="pb-16 mb-12"
        >
          <VTabItem
            value="#main"
            :transition="false"
          >
            <VContainer>
              <VRow>
                <VCol>
                  <TTTextField
                    v-model="title"
                    maxlength="100"
                    label="Название"
                    large
                    placeholder="Например, рога и копыта"
                    data-test-label="input-track-name"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <TTTextarea
                    v-model="description"
                    label="Описание"
                    placeholder="Введите описание группы"
                    maxlength="500"
                    data-test-label="textarea-track-description"
                    counter
                  />
                </VCol>
              </VRow>
            </VContainer>
          </VTabItem>
          <VTabItem
            :transition="false"
            value="#conditions"
          >
            <VContainer class="px-0">
              <VRow>
                <VCol>
                  <div class="tt-text-title-1">
                    Автоматически добавлять сотрудников, если:
                  </div>
                </VCol>
              </VRow>
              <VRow>
                <VCol cols="8">
                  <!--Condition constructor-->
                  <ConditionConstructor
                    :tree-init.sync="tree"
                    @click:group-create="handleCreateGroup"
                    @click:condition-remove="handleDeleteCondition"
                    @click:condition-create="handleCreateCondition"
                  />
                  <!--Condition constructor end-->
                </VCol>
              </VRow>
            </VContainer>
          </VTabItem>
        </VTabsItems>
      </VCol>
    </VRow>
    <NavigationDrawer
      :title="navigationDrawerTitle"
      :menu-items="menuItems"
    />
    <VSnackbar
      v-model="showSaveButtons"
      timeout="-1"
      light
      min-width="768"
      elevation="0"
      class="pb-9"
      data-test="track-snackbar-actions"
      content-class="card-shadow"
    >
      <VRow justify="end">
        <VCol cols="auto">
          <TTBtn
            data-test-label="button-save"
            @click="handleClickSave"
          >
            Сохранить
          </TTBtn>
        </VCol>
      </VRow>
    </VSnackbar>
  </VContainer>
</template>

<script>
import NavigationDrawer from '@/components/app/drawers/NavigationDrawer.vue';
import ConditionConstructor from '@/components/consturctor/ConditionConstructor.vue';

export default {
  name: 'GroupsManagement',
  components: {
    ConditionConstructor,
    NavigationDrawer,
  },
  inject: ['Names'],
  data() {
    return {
      activeTab: '',
      title: '',
      description: '',
      showSaveButtons: true,
      tree: [],
    };
  },
  computed: {
    menuItems() {
      return [
        {
          name: this.$route.name,
          text: 'Основное',
          hash: 'main',
        },
        {
          name: this.$route.name,
          text: 'Условия автодобавления',
          hash: 'conditions',
        },
      ];
    },
    isEditState() {
      return !!this.$route.params.id;
    },
    navigationDrawerTitle() {
      return this.isEditState ? 'Редактирование группы' : 'Создание группы';
    },
  },
  watch: {
    $route: {
      handler(value) {
        if (!value.hash) {
          console.log('test');
          this.setActiveTab('#main');
          this.$router.replace({ hash: '#main' });
        } else if (value.hash && this.activeTab !== value.hash) {
          this.setActiveTab(value.hash);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$root.$on('click:group-close', this.goToGroups);
  },
  beforeDestroy() {
    this.$root.$off('click:group-close', this.goToGroups);
  },
  methods: {
    goToGroups() {
      if (this.isEditState) {
        this.$router.push({
          name: this.Names.R_GROUP,
          params: { id: this.$route.params.id },
        });
      } else {
        this.$router.push({ name: this.Names.R_GROUPS });
      }
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    // TODO Здесь будут потом экшены из апишки
    handleClickSave() {
      this.$root.$emit('showNotifier', {
        title: 'Сохраним сразу, как только сможем',
        timeout: 6000,
      });
    },
    handleCreateGroup() {
      this.tree.push({
        groupIndex: this.tree.length,
        children: [{
          rule: 'is',
          option: 'designer',
        }],
      });
    },
    handleDeleteCondition({
      groupIndex,
      conditionIndex,
    }) {
      if (this.tree[groupIndex].children.length === 1) {
        this.tree.splice(groupIndex, 1);
      } else {
        this.tree[groupIndex].children.splice(conditionIndex, 1);
      }
    },
    handleCreateCondition({ groupIndex }) {
      this.tree[groupIndex].children.push({
        rule: 'is',
        option: 'designer',
      });
    },
  },
};
</script>
